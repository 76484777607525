import { Button, Menu } from "antd";
import { AppstoreOutlined, SettingOutlined } from "@ant-design/icons";

import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Link } from "react-router-dom";

const AccountIcon = () => {
  const { isLoggedIn, signOut } = useContext(AuthContext);

  if (isLoggedIn) {
    return (
      <Menu mode="horizontal" theme="dark" className="LoginMenu">
        <Menu.SubMenu
          key="SubMenu"
          title="Your Account"
          icon={<SettingOutlined />}
        >
          {/*
          <Menu.Item key="two" icon={<AppstoreOutlined />}>
            Navigation Two
          </Menu.Item>
          <Menu.Item key="three" icon={<AppstoreOutlined />}>
            Navigation Three
          </Menu.Item>
          <Menu.ItemGroup title="Item Group">
            <Menu.Item key="four" icon={<AppstoreOutlined />}>
              Navigation Four
            </Menu.Item>
            <Menu.Item key="five" icon={<AppstoreOutlined />}>
              Navigation Five
            </Menu.Item>
          </Menu.ItemGroup>
    */}
          <Menu.Item
            onClick={() => signOut()}
            key="signout"
            icon={<AppstoreOutlined />}
          >
            Log Out
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  }

  return (
    <div>
      <Link to="/login">
        <Button type="primary">Log In </Button>
      </Link>
    </div>
  );
};

export default AccountIcon;
