import { Button, Checkbox, Form, Input, Spin } from "antd";
import React, { useContext, useState } from "react";

import { Auth } from "aws-amplify";
import { useNavigate } from "react-router";
import { AuthContext } from "../../context/authContext";

const ConfirmEmail = (props: any) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { assessLoggedInState } = useContext(AuthContext);

  const navigateTo = useNavigate();

  const onFinish = async ({
    email,
    code,
    remember,
  }: {
    email: string;
    code: string;
    remember: boolean;
  }) => {
    try {
      setLoading(true);

      await Auth.confirmSignUp(email, code);

      if (remember) {
        window.localStorage.setItem("savedEmailLogin", email);
      } else {
        window.localStorage.setItem("savedEmailLogin", "");
      }
      setTimeout(() => {
        assessLoggedInState();
      }, 1500);
    } catch (error) {
      console.log(error);

      if (
        (error as any).message ===
        "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        navigateTo("/login");
      }
      setError("Confirming the code failed, please try again");
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const initialEmail = window.localStorage.getItem("savedEmailLogin") || "";

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <h2>You should have been sent a code to your email address</h2>
      <Form.Item
        label="Email"
        name="email"
        initialValue={initialEmail}
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: "Please input your email!" },
        ]}
      >
        <Input
          onChange={() => {
            setError("");
          }}
        />
      </Form.Item>
      <Form.Item
        label="Code"
        name="code"
        rules={[
          {
            required: true,
            message: "Please input the code sent to your email address",
          },
        ]}
      >
        <Input
          onChange={() => {
            setError("");
          }}
        />
      </Form.Item>
      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <div className="flex flex-col items-center">
          <Checkbox defaultChecked={!!initialEmail}>Remember me</Checkbox>
        </div>
      </Form.Item>
      {error && (
        <div
          className="text-red-600 text-center"
          style={{ paddingLeft: "33%" }}
        >
          {error}
        </div>
      )}
      {loading ? (
        <div className="text-center" style={{ paddingLeft: "33%" }}>
          <Spin />
        </div>
      ) : (
        <>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button w-full"
            >
              Confirm Code
            </Button>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button
              //type="primary"
              //htmlType="submit"
              className="login-form-button  w-full"
            >
              Resend Code
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default ConfirmEmail;
