import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { GetHistoryResponse } from "../../../types/api";
import "./video.css";

import API from "../../../Utils/API";
import { AxiosError } from "axios";
import { Button, Modal } from "antd";

export default function VideoViewData({ isLoggedIn }: any) {
  const { videoId } = useParams();
  const [videoData, setVideoData] = useState<GetHistoryResponse | undefined>();
  const [videoError, setVideoError] = useState<string>("");
  const [submitError, setSubmitError] = useState<string>("");

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOk = () => {
    setIsModalOpen(false);
  };

  const getData = async () => {
    try {
      const data = await API.get<GetHistoryResponse>({
        path: `/history/${videoId}`,
      });
      setVideoData(data);
      if (data.all.length < 2) {
        setIsModalOpen(true);
      }
    } catch (error) {
      console.log("error", error);
      if ((error as AxiosError)?.response?.status === 404) {
        setVideoError(
          `We aren't tracking this video. Do you want us to track it?`
        );
      }
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = async () => {
    try {
      if (!videoId) {
        setSubmitError(`That isn't a valid Youtube video URL`);
        return;
      }
      // cors always blocks this
      //await axios.head(`https://i.ytimg.com/vi/${videoId}/default.jpg`);
    } catch (error) {
      console.log(error);
      setSubmitError(`That isn't a valid Youtube video URL`);
      return;
    }
    try {
      await API.post({ path: `/monitor/${videoId}`, data: {} });

      navigate(`/track/video/${videoId}`);
    } catch (error) {
      setSubmitError("Error monitoring that video");
    }
  };

  return (
    <div className="h-full w-full flex flex-col justify-between items-center relative">
      <Modal
        title="Basic Modal"
        centered
        open={isModalOpen}
        footer={[
          <Button key="ok" type="primary" onClick={handleModalOk}>
            Ok
          </Button>,
        ]}
      >
        <p>You're the first person to track this video</p>
        <p>So there in no data yet</p>
        <p>Check back in a day or two to see the data!</p>
      </Modal>
      <div className="text-2xl text-center pt-8">
        Video and Thumbnail Changes
      </div>
      {videoData && (
        <div className="h-full w-full flex flex-col items-center">
          <div>{videoData.meta.title}</div>
          <SimpleChart videoData={videoData} />
        </div>
      )}

      {videoError && (
        <div className="h-full w-full flex flex-col items-center">
          <div>{videoError}</div>
          <Button onClick={() => submit()}>Yes</Button>
          {submitError && <div>{submitError}</div>}
        </div>
      )}
    </div>
  );
}

const CustomTooltip = ({
  active,
  payload,
  label,
}: {
  active: boolean;
  payload: { payload: GetHistoryResponse["all"][0] }[];
  label: string;
}) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.thumbnailChange &&
    payload[0]?.payload?.titleChange
  ) {
    const tnChange = payload[0]?.payload?.thumbnailChange;
    return (
      <div className="custom-tooltip-with-image bg-white text-gray-900 p-3">
        <p className="label">{`${payload[0].payload.views} views`}</p>
        <p className="label">{`${new Date(
          payload[0].payload.date
        ).toDateString()}`}</p>
        <p>New Title: {payload[0]?.payload?.titleChange.newTitle}</p>
        {payload[0]?.payload?.titleChange.oldTitle && (
          <p>Old Title: {payload[0]?.payload?.titleChange.oldTitle}</p>
        )}
        <img src={tnChange.newThumbnailS3Url} alt="" />
      </div>
    );
  }
  if (
    active &&
    payload &&
    payload.length &&
    payload[0]?.payload?.thumbnailChange
  ) {
    const tnChange = payload[0]?.payload?.thumbnailChange;
    return (
      <div className="custom-tooltip-with-image bg-white text-gray-900 p-3">
        <p className="label">{`${payload[0].payload.views} views`}</p>
        <p className="label">{`${new Date(
          payload[0].payload.date
        ).toDateString()}`}</p>
        <img src={tnChange.newThumbnailS3Url} alt="" />
      </div>
    );
  }
  if (active && payload && payload.length && payload[0]?.payload?.titleChange) {
    return (
      <div className={"custom-tooltip bg-white text-gray-900 p-3"}>
        <p className="label">{`${payload[0].payload.views} views`}</p>
        <p className="label">{`${new Date(
          payload[0].payload.date
        ).toDateString()}`}</p>
        <p>New Title: {payload[0]?.payload?.titleChange.newTitle}</p>
        {payload[0]?.payload?.titleChange.oldTitle && (
          <p>Old Title: {payload[0]?.payload?.titleChange.oldTitle}</p>
        )}
      </div>
    );
  }
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip  bg-white text-gray-900 p-3">
        <p className="label">{`${payload[0].payload.views} views`}</p>
        <p className="label">{`${new Date(
          payload[0].payload.date
        ).toDateString()}`}</p>
      </div>
    );
  }

  return <></>;
};

const SimpleChart = ({ videoData }: { videoData: GetHistoryResponse }) => {
  const [data, setData] = useState<GetHistoryResponse["all"]>(videoData.all);
  const [yRange, setYRange] = useState<[number, number | "auto"]>([0, "auto"]);

  const [xRange /*, setXRange */] = useState<[number, number]>([
    videoData.all[0].date,
    videoData.all[videoData.all.length - 1].date,
  ]);

  const convertDate = (timestamp: number) => {
    const dateRange =
      videoData.all[videoData.all.length - 1].date - videoData.all[0].date;
    if (dateRange > 1000 * 60 * 60 * 24 * 2) {
      return new Date(timestamp).toLocaleDateString().slice(0, 5);
    }
    return new Date(timestamp).toTimeString().slice(0, 5);
  };

  const numberToString = (number: number) => {
    const length = number.toFixed(0).length;

    if (number < 10000) return number.toFixed(0);
    if (number <= 1000000) return (number / 1000).toFixed(1) + "K";
    if (length === 7) return (number / 1000000).toFixed(2) + "M";
    return (number / 1000000).toFixed(1) + "M";
  };

  const getAxisYDomain = (from: number, to: number) => {
    const refData: any[] = data.slice(from, to + 1);

    console.log("refData[0]", refData[0]);

    let viewMin = refData[0].views;
    let viewMax = refData[refData.length - 1].views;
    const dif = viewMax - viewMin;
    const showMin = viewMin - dif * 0.2;
    const showMax = viewMax + dif * 0.2;

    setYRange([showMin, showMax]);
  };

  useEffect(() => {
    setData(videoData.all);
    getAxisYDomain(0, videoData.all.length - 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ResponsiveContainer height={700} width="80%">
      <ComposedChart
        width={800}
        height={300}
        data={data}
        margin={{ top: 25, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <linearGradient id="colorUv" x1={0} y1={0} x2={0} y2={1}>
            <stop offset="5%" stopColor="#4CAF50" stopOpacity={0.2} />
            <stop offset="95%" stopColor="#FFFFFF" stopOpacity={0.1} />
          </linearGradient>
        </defs>
        <XAxis dataKey="date" domain={xRange} tickFormatter={convertDate} />
        <YAxis tickFormatter={numberToString} domain={yRange} />
        {
          //@ts-expect-error
          <Tooltip offset={10} content={<CustomTooltip />} />
        }
        <CartesianGrid vertical={false} stroke="#DDD" />
        <Line
          type="monotone"
          unit=" views"
          strokeLinecap="round"
          strokeWidth={2}
          dataKey="views"
          stroke="#006991"
          dot={false}
          legendType="none"
        />
        <Area
          type="monotone"
          dataKey="views"
          stroke="true"
          strokeWidth={2}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        {data
          .filter((record) => record.titleChange)
          .map((record) => (
            <ReferenceDot
              key={`title-${record.date}`}
              x={record.date}
              y={record.views}
              r={5}
              fill="blue"
              stroke="none"
            />
          ))}
        {data
          .filter((record) => record.thumbnailChange)
          .map((record) => (
            <ReferenceDot
              key={`thumbnail-${record.date}`}
              x={record.date}
              y={record.views}
              r={5}
              fill="red"
              stroke="none"
            />
          ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
