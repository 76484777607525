import React from "react";

import { Layout, Affix, Menu, ConfigProvider, theme } from "antd";
import { LocalNavigation, INavItems } from "./Navigation";
import AccountIcon from "./AccountIcon";

const { Header, Sider, Content } = Layout;

const LOCAL_ROUTER_CONFIG: INavItems = [
  { route: "/competitors", displayName: "competitors" },
  { route: "/video", displayName: "VideoTracking" },
];

const SSRApp = ({ children = <></> }: { children?: JSX.Element }) => {
  return (
    <Layout style={{ background: "#f0f2f5", minHeight: "100vh" }}>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(0,0,0,.1)",
          padding: "0 25px",
        }}
      >
        <div className="flex justify-start items-center">
          <img id="headerLogo" src="/YoutubeUtils.png" alt="logo" />
          <h1
            style={{ color: "#fff", fontSize: "xxx-large", marginLeft: "40px" }}
          >
            Vid Toolkit
          </h1>
        </div>
        <AccountIcon />
      </Header>
      <Layout>
        <Sider style={{ minHeight: "100%", background: "white" }}>
          <Affix>
            <LocalNavigation navItems={LOCAL_ROUTER_CONFIG} />
          </Affix>

          <div
            style={{
              position: "absolute",
              bottom: "10px",
              width: "100%",
            }}
          >
            <ConfigProvider
              theme={{
                algorithm: theme.defaultAlgorithm,
              }}
            >
              <Menu mode="inline">
                <Menu.Item
                  title="Video Tracking"
                  key="VideoTracking"
                  onClick={() => (window.location.href = "/about")}
                >
                  About
                </Menu.Item>

                <Menu.Item
                  onClick={() => (window.location.href = "/track/channel")}
                  key="ChannelTracking"
                >
                  <a
                    href="https://twitter.com/SamCompleteCode"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Twitter
                  </a>
                </Menu.Item>
              </Menu>
            </ConfigProvider>
          </div>
        </Sider>
        <Content
          style={{
            overflowY: "scroll",
            maxHeight: "calc(100vh - 64px)",
            background: "rgb(31,41,55)",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </Content>
      </Layout>
      {/* <Footer>Footer</Footer> */}
    </Layout>
  );
};

export default SSRApp;
