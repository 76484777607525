import React from "react";
import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import About from "../views/About";
import AgreementForSignup from "../views/AgreementForSignup";
//import ChannelHomePage from "../views/Channel";
import ConfirmEmail from "../views/ConfirmEmail";
import Login from "../views/Login";
import Signup from "../views/Signup";
import VideoHomePage from "../views/video";
import VideoViewData from "../views/video/[videoId]";

const Router = ({ pageProps = {} }: { pageProps?: any }) => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    // show the
    return (
      <Routes>
        <Route path="/login" element={<Navigate replace to="/" />} />
        <Route path="/signup" element={<Navigate replace to="/" />} />
        <Route path="/confirm-email" element={<Navigate replace to="/" />} />

        <Route
          path="/track/video"
          element={<VideoHomePage isLoggedIn={isLoggedIn} />}
        />
        <Route
          path="/track/video/:videoId"
          element={<VideoViewData isLoggedIn={isLoggedIn} />}
        />
        <Route path="/track/channel" element={<h2>Coming Soon</h2>} />
        <Route path="/competitors" element={<h2>Coming Soon</h2>} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/agreement-signup" element={<AgreementForSignup />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />

      <Route
        path="/track/video"
        element={<VideoHomePage isLoggedIn={isLoggedIn} />}
      />
      <Route
        path="/track/video/:videoId"
        element={<VideoViewData isLoggedIn={isLoggedIn} />}
      />
      <Route path="/track/channel" element={<h2>Coming Soon</h2>} />
      <Route path="/competitors" element={<h2>Coming Soon</h2>} />
    </Routes>
  );
};

export default Router;
