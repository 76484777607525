import { useEffect, useState } from "react";
import queryString from "query-string";
import { Link, useNavigate } from "react-router-dom";
import { Button, Checkbox, Table } from "antd";
import API from "../../../Utils/API";

export default function VideoHomePage({ isLoggedIn }: { isLoggedIn: boolean }) {
  const [videoUrl, setVideoUrl] = useState("");
  const [showSignupMessage, setShowSignupMessage] = useState(false);
  const [error, setError] = useState("");

  interface UpdatedVideo {
    date: number;
    title: string;
    videoId: string;
    titleChange?: {
      newTitle: string;
      oldTitle?: string;
    };
    thumbnailChange?: {
      newThumbnailS3Url: string;
      oldThumbnailS3Url?: string;
    };
  }

  const [updatedVideos, setUpdatedVideos] = useState<UpdatedVideo[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    getChangedVideos();
  }, []);

  const getChangedVideos = async () => {
    const res = await API.get<UpdatedVideo[]>({ path: "/changes" });

    setUpdatedVideos(res);
  };

  const submit = async () => {
    setError("");

    const { v: videoId } = queryString.parse(videoUrl.split("?")[1]);
    try {
      if (!videoId) {
        setError(`That isn't a valid Youtube video URL`);
        return;
      }
      // cors always blocks this
      //await axios.head(`https://i.ytimg.com/vi/${videoId}/default.jpg`);
    } catch (error) {
      console.log(error);
      setError(`That isn't a valid Youtube video URL`);
      return;
    }
    if (!isLoggedIn && !showSignupMessage) {
      setShowSignupMessage(true);
      return;
    }

    try {
      await API.post({ path: `/monitor/${videoId}`, data: {} });

      navigate(`/track/video/${videoId}`);
    } catch (error) {
      setError("Error monitoring that video");
    }
  };
  //const { refetch, isLoading } = trpc.useMutation();

  return (
    <>
      <h2 className="text-2xl">Video and Thumbnail Changes</h2>

      <div className="flex flex-col items-center text-lg">
        <div>Enter the video that you want to track</div>
        <input
          type="text"
          className="w-full m-1 p-1 text-lg max-w-md"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
        />
        {!showSignupMessage && (
          <div>
            <Button size="large" onClick={submit}>
              Track
            </Button>
          </div>
        )}
        {showSignupMessage && (
          <div className="text-center">
            <div className="m-5">
              Since you're not logged in the video will be tracked daily. To see
              the changes every 30 minutes you can sign up for free
            </div>
            <Button className="m-3" size="large" onClick={submit}>
              Track Once A Day
            </Button>
            <Link to="/signup">
              <Button size="large" type="primary">
                Sign Up
              </Button>
            </Link>
          </div>
        )}
        {error && <div className="text-red">{error}</div>}
        <h2>Videos that have been updated</h2>
        <Table
          dataSource={updatedVideos}
          pagination={false}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
              key: "title",
            },
            {
              title: "Title Change",
              key: "titleChange",
              dataIndex: "titleChange",
              render: (_, { titleChange }) => (
                <div className="text-center">
                  <Checkbox
                    onChange={() => {}}
                    checked={!!titleChange}
                  ></Checkbox>
                </div>
              ),
            },
            {
              title: "Thumbnail Change",
              key: "thumbnailChange",
              dataIndex: "thumbnailChange",
              render: (_, { thumbnailChange }) => (
                <div className="text-center">
                  <Checkbox
                    onChange={() => {}}
                    checked={!!thumbnailChange}
                  ></Checkbox>
                </div>
              ),
            },
            {
              title: "View Tacking",
              key: "view",
              dataIndex: "videoId",
              render: (_, { videoId }) => (
                <Link to={`/track/video/${videoId}`}>View Tracking</Link>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
