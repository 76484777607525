import React from "react";

export const AuthContext = React.createContext<{
  isLoggedIn: boolean;
  assessLoggedInState: () => void;
  signOut: () => void;
}>({
  isLoggedIn: false,
  assessLoggedInState: () => {},
  signOut: () => {},
});
