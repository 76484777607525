import React, { useEffect, useState } from "react";

import "./App.css";
import { Amplify, Auth } from "aws-amplify";

import Layout from "./components/common/Layout";
import cognitoConfig from "./cognito";
import Router from "./components/router";
import { AuthContext } from "./components/context/authContext";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

function App({ pageProps = {} }: { pageProps?: any }) {
  const description = "An app to help you become the most amazing youtubers ";
  const title = "Vid Toolkit";
  const imageMetaURL = "https://youtube-utils.vercel.app/YoutubeUtils.png";
  Amplify.configure(cognitoConfig[process.env.env as "dev" | "prod"]);

  const [isLoggedIn, setLoggedIn] = useState(false);

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((sess) => {
        setLoggedIn(true);
      })
      .catch((error) => {
        setLoggedIn(false);
      });
  };

  useEffect(() => {
    assessLoggedInState();
  });

  const signOut = async () => {
    await Auth.signOut();
    setTimeout(() => {
      assessLoggedInState();
    }, 500);
  };

  return (
    <>
      <head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link
          rel="icon"
          href={"https://youtube-utils.vercel.app/favicon.ico"}
        />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageMetaURL} />
        <meta name="twitter:image" content={imageMetaURL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="theme-color" content="#000000" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png?v=2"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png?v=2"
        />
        <link rel="manifest" href="/site.webmanifest?v=2" />
        <link rel="shortcut icon" href="/favicon.ico?v=2" />
      </head>
      <AuthContext.Provider
        value={{ assessLoggedInState, isLoggedIn, signOut }}
      >
        <div className="App">
          <BrowserRouter>
            <ConfigProvider
              theme={{
                algorithm: theme.darkAlgorithm,
              }}
            >
              <Layout>
                <Router pageProps={pageProps} />
              </Layout>
            </ConfigProvider>
          </BrowserRouter>
        </div>
      </AuthContext.Provider>
    </>
  );
}

export default App;
