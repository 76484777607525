import React from "react";

const AgreementForSignup = (props: any) => {
  return (
    <>
      <h1 className="text-5xl">Terms of the Agreement</h1>
      <div className="w-9/12">
        <h2>1. Services</h2>
        <ol>
          <li>
            <b>Purpose:</b> The Company agrees to provide Vid Toolkit
            (hereinafter referred to as “Platform”) by Complete Coding as a SaaS
            (Software as a Service) for analysis of Youtube data and also
            provide for the maintenance and support.
          </li>
          <li>
            <b>Services:</b> The Company shall provide the best possible
            services:
            <ol>
              <li>Customer and Authorized Users access to the Platform.</li>
              <li>Customer Professional Services.</li>
            </ol>
          </li>
          <li>
            <b>Subscription Audits:</b> Each subscription service and other
            related professional services shall provide the specification of the
            services, the platforms used, user limitations, the subscription
            term, and terms and conditions.
          </li>
          <li>
            <b>Changes to Platform:</b>The Company may, in its sole discretion,
            make any changes to any Platform that it deems necessary or useful
            to:
            <ol>
              <li>
                Maintain or enhance
                <ol>
                  <li>
                    The quality or delivery of the Company's products or
                    services to its customers
                  </li>
                  <li>
                    The competitive strength of, or market for, the Company's
                    products or services
                  </li>
                  <li>Such Platform's cost efficiency or performance</li>
                </ol>
              </li>
              <li>Comply with applicable law.</li>
            </ol>
          </li>
        </ol>
        <h2> Platform Access and Authorized User</h2>
        <ol>
          <li>
            <b>Administrative Users:</b> During the configuration and set-up
            process for the Platform, the Customer will identify an
            administrative user name and password for the Customer's account
            with the Company. The Company shall reserve the right to refuse
            registration of or cancel user names and passwords or delete
            accounts as it deems inappropriate.
          </li>
          <li>
            <b>Authorized Users:</b> Customers may allow a number of employees
            and/or independent contractors as is indicated in their subscription
            level to use the applicable Platform on behalf of Customer as
            “Customer Users.” Authorized User subscriptions are for designated
            Authorized Users and cannot be shared or used by more than one
            Authorized User, but may be reassigned to new Authorized Users.
          </li>
          <li>
            <b>Authorized User Conditions to Use:</b>
            <ol>
              <li>
                As a condition to access and use of a Platform
                <ol>
                  <li>
                    Each Authorized User shall agree to abide by the terms of
                    the Company's end-user terms of use which it may adopt from
                    time to time.
                  </li>
                  <li>
                    Customer Users shall agree to abide by the terms of this
                    Agreement, or a subset thereof.
                  </li>
                  <li>
                    Vendor Users shall agree to abide by the terms of the
                    Company's Vendor Terms of Service applicable to such
                    Platform, and, in each case, Customer shall ensure such
                    compliance.
                  </li>
                </ol>
              </li>
              <li>
                The Customer shall immediately notify the Company of any
                violation of the terms of any of the foregoing by any Authorized
                User upon becoming aware of such violation.
              </li>
            </ol>
          </li>
          <li>
            <b>Account Responsibility:</b>
            <ol>
              <li>
                {" "}
                The Customer shall be responsible for:
                <ol>
                  <li>
                    {" "}
                    All uses of any account that Customer has access to, whether
                    or not the Customer has authorized the particular use or
                    user, and regardless of Customer's knowledge of such use.
                  </li>
                  <li>
                    Securing the Company account, passwords (including but not
                    limited to administrative and user passwords), and files.
                  </li>
                </ol>
              </li>
              <li>
                The Company shall not be responsible for any loss of information
                due to any irresponsible act such as loss of the password by the
                user.
              </li>
            </ol>
          </li>
        </ol>
        <h2>Confidentiality</h2>
        All confidential information that is communicated to and obtained by the
        Company from the Customer in connection with performing the
        above-mentioned services shall be held by the Company in full trust. At
        no time the Company shall use any confidential information obtained
        through conducting this service contract either directly or indirectly,
        for personal benefit, or disclose or communicate such information in any
        manner.
        <h2>Proprietary Rights</h2>
        <ol>
          <li>
            <b>Ownership:</b>
            <ol>
              <li>
                The Company shall reserve the right to all title interest.
              </li>
              <li>
                The Company shall own and retain all rights, title, and interest
                in:
                <ol>
                  <li>
                    Any kind of services software, applications, inventions, or
                    other technology developed in connection with the Services;
                  </li>
                  <li>
                    All intellectual property and proprietary rights in and
                    related to any of the foregoing subscription services
                    (collectively, “Services IP”).
                  </li>
                </ol>
              </li>
              <li>
                To the extent Customer acquires any right, title, or interest in
                any Services IP, Customer hereby assigns all of its right,
                title, and interest in such Services IP to the Company.
              </li>
            </ol>
          </li>
          <li>
            <b>Customer Data and Vendor Information License:</b> Customer hereby
            grants the Company a non-exclusive, transferable, sublicensable,
            worldwide, and royalty-free license to use and otherwise exploit (i)
            Customer Data to provide the Services to Customer hereunder and as
            necessary or useful to monitor and improve a Platform, Software and
            Services, both during and after the Term, and (ii) Vendor
            Information for any lawful purpose. To avoid any doubt, Complete
            Coding shall use, reproduce and disclose Platform, Software and
            Services-related information, data, and material that is anonymized,
            de-identified, or otherwise rendered not reasonably associated or
            linked to Customer, all of which information, data, and material,
            will be owned by Complete Coding.) It is Customer's sole
            responsibility to back-up Customer Data during the Term, and
            Customer acknowledges that it will not have access to Customer Data
            through Complete Coding or any Platform following the expiration or
            termination of this Agreement.
          </li>
          <li>
            <b>Intellectual Property:</b> The Company shall retain all the
            subscription services, including all documentation, modifications,
            improvements, upgrades, derivative works, and all other Intellectual
            Property rights in connection with the Service, including the
            Company's name, logos and trademarks reproduced through the Service.
          </li>
        </ol>
        <h2>Payment Terms</h2>
        <ol>
          <li>
            The Customer shall pay the Company the then-applicable fees
            described in an Order Form or Statement of Work, as applicable, in
            accordance with the terms set forth therein (“Fees”).
          </li>
          <li>
            The Company may choose to bill through an invoice, in which case,
            full payment for invoices issued in any given month must be received
            by the Company 15 days after the mailing date of the invoice (unless
            otherwise specified on the applicable Order Form).
          </li>
          <li>
            Unpaid amounts are subject to a finance charge each month on any
            outstanding balance.
          </li>
          <li>
            The Company further reserves the right to suspend Services in the
            event of payment delinquency.
          </li>
        </ol>
        <h2>Security Compliance</h2>
        <ol>
          <li>
            <b>Compliance with Notification Laws:</b> The Company shall comply
            with all applicable laws regarding the notification of individuals
            in the event of an unauthorized release of personally identifiable
            information and notification other unauthorized data and information
            disclosures.
          </li>
          <li>
            <b>Procedure After Unauthorized Disclosure:</b> Within 30 of
            discovering any breach of the Company's security obligations or any
            other event requiring notification under applicable law, The Company
            shall notify Customer, and any other individual's Law requires to be
            notified, of the breach or other events by telephone and e-mail.
          </li>
        </ol>
        <h2>Termination</h2>
        <ol>
          <li>
            Customer may terminate this agreement for any reason on 0 days'
            notice to the Company.
          </li>
          <li>
            Each party may terminate this agreement with immediate effect by
            delivering notice of the termination to the other party, if:
            <ol>
              <li>
                The other party has made any inaccuracy in, or otherwise
                materially breaches, any of its obligations, covenants, or
                representations, and;
              </li>
              <li>
                The failure, inaccuracy, or breach continues for a period of 30
                days' after the injured party delivers notice to the breaching
                party reasonably detailing the breach.
              </li>
            </ol>
          </li>
          <li>
            The Company may terminate this agreement with immediate effect by
            delivering notice of the termination to the Customer if the Customer
            fails to pay the invoice amount on time 1 time over any term.
          </li>
          <li>
            Upon termination of this Agreement, the Company shall cease
            reproducing, advertising, marketing, and distributing any material
            or information pertaining to the Customer immediately.
          </li>
        </ol>
        <h2>Indemnification</h2>
        <ol>
          <li>
            The Parties each agree to indemnify and hold harmless the other
            Party, its respective affiliates, employees, and permitted
            successors and assigns against any losses, claims, damages,
            penalties, liabilities, punitive damages, expenses, reasonable legal
            fees of whatsoever kind or amount, which result from the negligence
            of or breach of this Agreement by the indemnifying party, its
            respective affiliate or successors and any assign that occurs in
            connection with this Agreement. This section remains in full force
            and effect even after the termination of the Agreement.
          </li>
        </ol>
        <h2>Limitation of Liability</h2>
        <ol>
          <li>
            In no event shall either Party:
            <ol>
              <li>
                have liability arising out of or related to this Agreement,
                whether, in contract, tort or under any other theory of
                liability exceed in the aggregate of the total fees paid or owed
                by the Customer and Vendors hereunder during the 3 months
                immediately preceding the date of the event giving rise to the
                claim (such amount being intended as a cumulative cap and not
                per incident).
              </li>
              <li>
                have any liability to the other for any lost profits or revenues
                or any indirect, incidental, consequential, cover, special,
                exemplary, or punitive damages, howsoever caused, whether, in
                contract, tort, or under any other conditions of liability.
              </li>
            </ol>
          </li>
          <li>
            The foregoing limitations and disclaimers shall not apply to the
            extent prohibited by applicable law.
          </li>
        </ol>
        <h2>Arbitration</h2>
        <ol>
          <li>
            In the event of any dispute arising in and out of this Agreement
            between the Parties, it shall be resolved by Arbitration. There
            shall be 1 Arbitrator which shall be appointed by Complete Coding.
            The venue of Arbitration shall be Germany. The Arbitrators' decision
            shall be final and will be binding on both the Parties.
          </li>
        </ol>
        <h2>Miscellaneous</h2>
        <ol>
          <li>
            <b>Assignability:</b> Neither party may assign this Agreement or the
            rights and obligations thereunder to any third party without the
            prior express written approval of the other Party which shall not be
            unreasonably withheld.
          </li>
          <li>
            <b>Notices:</b> Any notice required to be given to the Customer
            shall be delivered by certified mail, personal delivery, or
            overnight delivery paid for by the Company.
          </li>
          <li>
            <b>Force Majeure:</b> Neither party shall be liable for any failure
            in performance of the obligation under this Agreement due to cause
            beyond that party's reasonable control (including and not limited to
            any pandemic, fire, strike, act or order of public authority, and
            other acts of God) during the pendency of such event.
          </li>
          <li>
            <b>Modification:</b> No modification of this Agreement shall be made
            unless in writing, signed by both parties.
          </li>
          <li>
            <b>Severability:</b> If any term, clause, or provision hereof is
            held invalid or unenforceable by a court of competent jurisdiction,
            all other terms will remain in full force and effect until the
            Agreement termination.
          </li>
          <li>
            <b>Governing Law and Jurisdiction:</b> This Agreement shall be
            governed by the laws of the Germany. If the disputes under this
            Agreement cannot be resolved by Arbitration, they shall be resolved
            by litigation in the courts of the Germany including the federal
            courts therein, and the Parties all consent to the jurisdiction of
            such courts, agree to accept service of process by mail and hereby
            waive any jurisdictional or venue defenses otherwise available to
            it.
          </li>
          <li>
            <b>Legal and Binding Agreement:</b> This Agreement is legal and
            binding between the Parties as stated above. This Agreement may be
            entered into and is legal and binding in the Germany. The Parties
            each represent that they have the authority to enter into this
            Agreement.
          </li>
          <li>
            <b> Entire Agreement:</b> This Agreement constitutes the entire
            understanding of the Parties, and revokes and supersedes all prior
            contracts between the Parties and is intended as a final expression
            of their Agreement. It shall not be modified or amended except in
            writing signed by the Parties hereto and specifically referring to
            this Agreement. This Agreement shall take precedence over any other
            documents which may conflict with this Agreement.
          </li>
        </ol>
      </div>
    </>
  );
};

export default AgreementForSignup;
