import { Button, Checkbox, Form, Input } from "antd";
import React, { useContext, useState } from "react";
import { login } from "../../../Utils/Auth";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

const Login = (props: any) => {
  const [error, setError] = useState("");
  const navigateTo = useNavigate();
  const authContext = useContext(AuthContext);

  const onFinish = async ({
    email,
    password,
    remember,
  }: {
    email: string;
    password: string;
    remember: boolean;
  }) => {
    try {
      await login({
        email,
        password,
      }).catch((error) => {
        if (error.code === "UserNotConfirmedException") {
          navigateTo("/confirm-email");
        }
        throw error;
      });

      if (remember) {
        window.localStorage.setItem("savedEmailLogin", email);
      } else {
        window.localStorage.setItem("savedEmailLogin", "");
      }
      authContext.assessLoggedInState();
    } catch (error) {
      console.log(error);
      setError("Wrong username or password, please try again");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const initialEmail = window.localStorage.getItem("savedEmailLogin") || "";

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Form.Item
        label="Email"
        name="email"
        initialValue={initialEmail}
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          { required: true, message: "Please input your email!" },
        ]}
      >
        <Input
          onChange={() => {
            setError("");
          }}
        />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your password!" }]}
      >
        <Input.Password
          onChange={() => {
            setError("");
          }}
        />
      </Form.Item>
      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <div className="flex flex-col items-center">
          <Checkbox defaultChecked={!!initialEmail}>Remember me</Checkbox>
          {/*<a className="login-form-forgot" href="">
            Forgot password
          </a>
        */}
        </div>
      </Form.Item>
      {error && (
        <div
          className="text-red-600 text-center"
          style={{ paddingLeft: "33%" }}
        >
          {error}
        </div>
      )}
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button
          type="primary"
          htmlType="submit"
          className="login-form-button w-full"
        >
          Log in
        </Button>
        <div className="p-3">
          Or <Link to="/signup">Register Now!</Link>
        </div>
      </Form.Item>
    </Form>
  );
};

export default Login;
