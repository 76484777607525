import React from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { signUp } from "../../../Utils/Auth";

const Signup = (props: any) => {
  const navigateTo = useNavigate();

  const onFinish = async ({ email, password, remember }: any) => {
    await signUp({
      password,
      email,
    });
    if (remember) {
      window.localStorage.setItem("savedEmailLogin", email);
    } else {
      window.localStorage.setItem("savedEmailLogin", "");
    }

    navigateTo("/confirm-email");
  };
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: {
      //xs: { span: 24 },
      sm: { span: 12 },
    },
    wrapperCol: {
      //xs: { span: 24 },
      sm: { span: 12 },
    },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Form
      {...formItemLayout}
      className="max-w-screen-sm"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ["zhejiang", "hangzhou", "xihu"],
        prefix: "86",
      }}
      scrollToFirstError
    >
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
          {
            required: true,
            message: "Please input your E-mail!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value) {
                return Promise.resolve();
              }
              const checkPassword = (str: string) => {
                var re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                return re.test(str);
              };
              if (checkPassword(value)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  "The password must be at least 8 characters, and have an upper case, lower case and number"
                )
              );
            },
          }),
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={["password"]}
        hasFeedback
        rules={[
          {
            required: true,
            message: "Please confirm your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error("Should accept agreement")),
          },
        ]}
        {...tailFormItemLayout}
      >
        <Checkbox>
          I have read the <Link to="/agreement-signup">agreement</Link>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <div className="flex flex-col items-center">
          <Checkbox>Remember me</Checkbox>
        </div>
      </Form.Item>
      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Register
        </Button>
      </Form.Item>
      <div className="text-center">
        <div>Already got an account?</div>
        <Link to="/login">
          <Button>Log In</Button>
        </Link>
      </div>
    </Form>
  );
};

export default Signup;
