import { Auth } from "aws-amplify";

export const signUp = async ({
  password,
  email,
}: {
  password: string;
  email: string;
}) => {
  await Auth.signUp({
    username: email.toLowerCase(),
    password,
    attributes: {
      email: email.toLowerCase(),
    },
    autoSignIn: {
      // optional - enables auto sign in after user is confirmed
      enabled: true,
    },
  });
  return true;
};

export const login = async ({
  password,
  email,
}: {
  password: string;
  email: string;
}) => {
  await Auth.signIn({
    username: email.toLowerCase(),
    password,
  });
};
