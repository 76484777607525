import React from "react";

const About = (props: any) => {
  return (
    <>
      <h1 className="text-5xl">Why Vid Toolkit</h1>

      <div className="text-lg w-1/2">
        <div>
          Vid Toolkit was created to allow youtubers to grow by learning from
          what other channels are doing.
        </div>
        <div>
          It started as a way for us to track thumbnail changes on other
          channels' videos, but is growing into something larger.
        </div>
      </div>
    </>
  );
};

export default About;
