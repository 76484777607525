const cognitoConfig = {
  dev: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_mAQARgeN7",
    aws_user_pools_web_client_id: "15dmmj5il11bd20r2juibgq48",
  },
  prod: {
    aws_project_region: "eu-central-1",
    aws_cognito_region: "eu-central-1",
    aws_user_pools_id: "eu-central-1_lFeLvTa23",
    aws_user_pools_web_client_id: "6aj92uqil175dahjc7dbka71kv",
  },
};

export default cognitoConfig;
