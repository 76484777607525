import React from "react";
import { ConfigProvider, Menu, theme } from "antd";
import {
  ReconciliationOutlined,
  FileSearchOutlined,
  StarOutlined,
} from "@ant-design/icons";

export type INavItems = Array<{
  route: string;
  displayName: string;
}>;

export const LocalNavigation = ({ navItems }: { navItems: INavItems }) => {
  const [defaultSelectedKeys, setDefaultSelectedKeys] =
    React.useState<string>("");

  React.useEffect(() => {
    const pathName = window.location.pathname;

    if (pathName.includes("/competitors")) {
      setDefaultSelectedKeys("competitors");
    }
    if (pathName.includes("/track/video")) {
      setDefaultSelectedKeys("VideoTracking");
    }
    if (pathName.includes("/track/channel")) {
      setDefaultSelectedKeys("ChannelTracking");
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[defaultSelectedKeys]}
        defaultOpenKeys={navItems.map((ni) => ni.displayName)}
      >
        <Menu.Item
          title="Video Tracking"
          key="VideoTracking"
          icon={<ReconciliationOutlined />}
          onClick={() => (window.location.href = "/track/video")}
        >
          Video Tracking
        </Menu.Item>

        <Menu.Item
          icon={<StarOutlined />}
          onClick={() => (window.location.href = "/track/channel")}
          key="ChannelTracking"
        >
          Channel Tracking
        </Menu.Item>

        <Menu.Item
          key="competitors"
          icon={<FileSearchOutlined />}
          onClick={() => (window.location.href = "/competitors")}
        >
          Competitors
        </Menu.Item>
      </Menu>
    </ConfigProvider>
  );
};
